:root {
  --text-color: #000;
  --main-blue: #2F80CA;
  --darker-blue: #1B4974;
  --purp-blue: #586BCA;
  --light-blue: rgba(20,180,231,1);
  --blue-gradient: linear-gradient(-45deg, var(--light-blue), var(--purp-blue)) !important;
  --transparent-blue: rgba(47, 128, 202, 0.6);
  --transparent-white: rgba(255, 255, 255, 0.9);
  --err-red: red;
  --blue-gradient: linear-gradient(0deg,  100%);
}

@media screen and (max-width: 399px) {
  #logo {
    padding-left: 2rem;
  }

  #hero-title {
    padding: 0.7rem;
    font-size: 3rem;
  }
}

@media screen and (min-width: 891px) {
  #logo {
    padding-left: 7rem;
  }
  #menu {
    flex-direction: row;
  }
  #menu-break {
    flex-basis: 0%;
    height: 0;
  }

  #info-wrp {
    padding: 6rem 3rem 0rem 3rem;
  }

  .companies-row {
    padding-top: 4rem;
  }
}

@media screen and (max-width: 550px) {
  #contact-info {
    width: 100%;
    align-self: center;
  }

  #contact {
    width: 88%;
    padding: 2rem;
  }

  #companies-wrp {
    padding-top: 4rem;
  }
}

@media screen and (min-width: 550px) {
  #contact {
    width: 60%;
    padding: 2rem 6rem 2rem 6rem;
  }
}

@media screen and (min-width: 550px) and (max-width: 949px) {
  #contact-info {
    width: 69%;
    align-self: center;
  }
}

@media screen and (max-width: 949px) {
  #contact-info {
    flex-direction: column;
  }

  #contact-info div {
    padding-top: 1rem;
  }
}


@media screen and (max-width: 890px) {
  #logo {
    padding-left: 2rem;
  }

  #hamburger {
    padding-right: 2rem;
  }

  #info-wrp, #companies-wrp {
    flex-direction: column;
  }

  #info-wrp div {
    flex-basis: 100%;
    max-width: 100%;
    padding-top: 3rem;
  }

  #menu {
    flex-direction: column;
    background: var(--transparent-white);
    border: 0.1rem solid var(--transparent-white);
    filter: drop-shadow(0 0 0.3rem var(--transparent-blue));
  }

  #menu-break {
    flex-basis: 100%;
    height: 0;
  }

  #kontakt {
    margin-bottom: 4.5rem;
  }
}

/* fonts */

@font-face {
  font-family: 'Montserrat-Regular';
  font-display: swap;
  src: url('../fonts/Montserrat-Regular.ttf');
}   

@font-face {
  font-family: 'Montserrat-Medium';
  font-display: swap;
  src: url('../fonts/Montserrat-Medium.ttf');
}   

/* general */

html, body, #wrapper, #root {
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: 'Montserrat-Regular', 'Arial';
  font-size: 12px;
}

h2, h3, h4, h5 {
  font-weight: 800;
  letter-spacing: .15rem;
  text-rendering: optimizeLegibility;
  margin-bottom: 16px;
  font-family: 'Montserrat-Medium', 'sans-serif';
}

h4 {
  line-height: 1.5rem;
  font-size: 1.4rem;
}

h3 {
  line-height: 2.3rem;
  font-size: 1.9rem;
}

h2 {
  font-size: 3rem;
  line-height: 3.3rem;
}

h1 {
  font-size: 3.6rem;
  line-height: 4rem;
}

a {
  color: var(--purp-blue);
  text-decoration: none;
}

p {
  font-size: 16px;
}

input[id="email"] {
  width: 50%;
}

input[id="subject"] {
  width: 50%;
}

input[type="text"], input[type="email"] {
  border-radius: 10px;
  height: 2rem;
  padding: 0.5rem;
}
textarea {
  border-radius: 20px;
  padding: 1rem;
}
textarea, input[type="text"], input[type="email"] {
  border: 0.1rem solid var(--main-blue);
}

#content {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}

#content-wrp {
  padding-top: 5rem;
  background: linear-gradient(179deg, #fff, var(--main-blue));
}

#logo {
  width: 15em;
  height: 4,5;
}

#menu {
  padding-right: 7rem;
}

#navbar {
  background-color: var(--transparent-white);
  height: 6.5em;
  z-index: 100;
}

#wrapper {
  background-position: center;
  background-size:cover;
  box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.55);
}

#hero {
  font-size: 20px;
  word-wrap: break-word;
  color: var(--transparent-white);
  z-index: 50; 
}

#info-wrp p {
  width: 80%;
}

#details-wrp, #companies-wrp {
  width: 70%;
  margin-left: 15%;
  align-items: center;
}

#oferta {
  padding-top: 10rem;
}

#companies-wrp p, #kontakt {
  padding-top: 2rem;
}

#contact-wrp h2 {
  margin-bottom: 4rem;
  margin-top: 0;
}

#contact {
  border: 0.1rem solid var(--transparent-white);
  border-radius: 20px;
  background-position: center;
  background-attachment: fixed;
  background-size:cover;
  box-shadow:inset 0 0 0 2000px rgba(255, 255, 255, 0.5);
  filter: drop-shadow(0.3rem 0.3rem 0.3rem var(--transparent-blue));
}

#contact > * {
  filter: drop-shadow();
}

#contact h3 {
  text-align: left;
  padding-top: 1.6rem;
}

#submit-btn {
  margin-top: 4rem;
  width: 20%;
}

#contact textarea {
  height: 8rem;
}

#send-ok {
  visibility: hidden;
  color: green;
  font-weight: 900;
  font-size: 0px;
}

/* general */

.err {
  color: var(--err-red);
}

.clickable:hover {
  cursor: pointer;
}

.xmax {
  width: 100%;
}

.ymax {
  height: 100%;
}

.centered {
  text-align: center;
}

.sticky {
  position: fixed;
  top: 0;
}

.m-1 {
  margin: 1px;
}

.svg-twhite-bg {
  fill: var(--transparent-white);
}

.svg-white-bg {
  fill: #fff;
}

.svg-blue-bg {
  fill: var(--main-blue);
}

.separator svg {
  height: 154px;
}

.separator {
  max-width: 100%;
  overflow: hidden;
  position: absolute;
}

.hidden {
  visibility: hidden;
}

.clr-dark {
  color: var(--darker-blue);
}

/* files */

.logo-box:hover {
  filter: drop-shadow(0.3rem 0.3rem 0.5rem var(--transparent-blue)) brightness(0.8);
}

.files-box {
  border-radius: 20px;
  height: auto;
  margin: 1rem;
  background-color: rgba(255, 255, 255, 0.69);
}

.files-box.hidden {
  opacity: 0;
  height: 0;
  padding-bottom: 0;
}

.files-box span {
  font-size: 1.5rem;
}

.file:hover {
  filter: drop-shadow(0.1rem 0.1rem 0.7rem var(--transparent-blue));
}

.file.hidden {
  font-size: 0;
}

.file-text {
  padding-top: 0.75rem;
  text-align: start;
}


/* flex */

.f-container {
  display: flex;
  list-style: none;
}

.f-wrap {
  flex-wrap: wrap;
}

.f-space {
  justify-content: space-between;
}

.f-j-center {
  justify-content: center;
}

.f-a-center {
  align-self: center;
}

.f-a-start {
  align-self: flex-start;
}

.f-end {
  align-self: flex-end;
}

.f-break {
  flex-basis: 100%;
  height: 0;
}

.f-row {
  flex-direction: row;
}

.f-col {
  flex-direction: column;
}

.f-nowrap {
  flex-wrap: nowrap;
}

.f-third {
  flex-basis: 33.333%;
}

/* font awesome */

.fa.icon.background {
  background: var(--main-blue);
  background-image: var(--blue-gradient) !important;
  transition: 0.5s;

  color: #fff;
}

.fa.icon:hover {
  background-position: right center !important;
}

.fa.icon.background.big {
  filter: drop-shadow(0.2rem 0.2rem 0.4rem var(--transparent-blue));
}

.fa.icon.background.medium {
  filter: drop-shadow(0.2rem 0.2rem 0.3rem var(--transparent-blue));
}

.fa.icon.big {
  font-size: 5rem;
  line-height: 6rem;
  width: 6rem;
  height: 6rem;
  padding: .5em;
}

.fa.icon.medium {
  font-size: 2rem;
  line-height: 2.5rem;
  padding: .5rem;
  width: 2.5rem;
  height: 2.5rem;
}

.fa.icon.small {
  font-size: 1rem;
  line-height: 1.25rem;
  padding: .3rem;
  width: 1.25rem;
  height: 1.25rem;
}

.fa.icon.round {
  border-radius: 100%;
}

.fa.icon.background:hover {
  background-color: var(--darker-blue);
}

.fa.icon:hover {
  filter: drop-shadow(0.1rem 0.1rem 0.03rem var(--transparent-blue));
}

/* buttons */

.btn {
  text-transform: uppercase;
  border: 0px;
}

.btn.big {
  font-size: 1.5rem;
  font-weight: 450;
  text-decoration: none;
  padding: 1.22rem 2rem;
  border-radius: 7px;
  line-height: 1.6rem;
  min-width: 10rem;
  transition: all 0.3s, background-color 0.4s;
}

.btn.margined {
  margin-right: 3.5rem;
  margin-left: 3.5rem;
  margin-top: 1rem;
}

.btn.round {
  border-radius: 18px;
}

.btn.blue {
  color: white;
  background: var(--main-blue);
  background-image: var(--blue-gradient) !important;
  background-size: 200% auto;
  transition: 0.5s;
}

.btn.blue:hover {
  background-position: right center;
}

.btn a:after {
  height: 0px;
}

/* top bar item */

.f-menu-item {
  color: var(--darker-blue);
  padding: 1em;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}

.f-menu-item:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  background: var(--main-blue);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.f-menu-item:hover:after { 
  width: 100%; 
  left: 0; 
}

p.bigger {
  font-size: 1.5rem;
}

.round-corners {
  border-radius: 24px;
}